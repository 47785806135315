@import '../../../../node_modules/react-phone-number-input/style.css';
@import '../../../controls';

.PhoneInputInput,
.PhoneInputCountry {
  @extend .form-control;
}

.PhoneInputCountryIcon svg {
  display: block;
  width: 100%;
  height: 100%;
}

.form-group.has-error .PhoneInputInput {
  border-color: #da0303;
}
